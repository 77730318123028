// import logo from './logo.svg';
import dweLogo from "./dwe-logo.svg";
import dropdown from "./dropdown.svg";
import close from "./close.svg";
import calendar from "./calendar.svg";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
// import CustomModal from "./CustomModal";
import "react-infinite-calendar/styles.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { apiCall } from "./utils/apiCall";
import { Buffer } from "buffer";
Modal.setAppElement("#root");

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const App = () => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD.replace("_PLACEHOLDER_", "$");
  const usernamePasswordBuffer = Buffer.from(username + ":" + password);
  const base64data = usernamePasswordBuffer.toString("base64");
  const isAndroid = /android/i.test(navigator.userAgent);
  const modalHeight =
    isAndroid && window.innerHeight < 800 ? window.innerHeight * 0.75 : "65%";

  const [modal, setModal] = useState("");
  const [name, setName] = useState("");
  const [kidsInfoCheckList, setKidsInfoCheckList] = useState([]);
  const [relation, setRelation] = useState("");
  const [address, setAddress] = useState("");
  const [extraAddress, setExtraAddress] = useState("");
  const [birthday, setBirthday] = useState("");
  const [bornDay, setBornDay] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [advisorInfo, setAdvisorInfo] = useState(null);
  const [showNameCard, setShowNameCard] = useState(false);
  const [missingItems, setMissingItems] = useState();
  const [kidName, setKidName] = useState("");
  const [memo, setMemo] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    Address1: "",
    ZIP: "",
    SGG_CD: "",
    ROAD_CD: "",
    EMD_CD: "",
    BULD_NO: "",
  });
  const [correctSMS, setCorrectSMS] = useState("");
  const [currentSMS, setCurrentSMS] = useState(["", "", "", ""]);
  const [pass, setPass] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [expiredTimer, setExpiredTimer] = useState(0);
  const [selectedGender, setSelectedGender] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const SMSInputs = useRef([]);
  const bornDatePickerRef = useRef(null); // 用于控制 DatePicker
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guid = queryParams.get("guid");
  const isFromEa = queryParams.get("source") === "ea";

  useEffect(() => {
    if (seconds <= 0) return;

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);
  useEffect(() => {
    if (expiredTimer <= 0) return;

    const intervalId = setInterval(() => {
      setExpiredTimer((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [expiredTimer]);

  const handleSMSChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const newSMS = [...currentSMS];
      newSMS[index] = value;
      setCurrentSMS(newSMS);

      // Automatically focus next input
      if (value && index < 3) {
        SMSInputs.current[index + 1].focus();
      }
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !currentSMS[index] && index > 0) {
      SMSInputs.current[index - 1].focus();
    }
  };

  const fetchData = async () => {
    if (guid) {
      const payload = {
        GUID: guid,
      };
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${base64data}`,
        },
      };
      try {
        const data = await apiCall({
          method: "POST",
          header: header,
          endPoint: process.env.REACT_APP_VERIFY,
          payload: payload,
        });
        setIsPageLoading(false);
        console.log("hello", data);
        setAdvisorInfo(data.data);
      } catch (error) {
        setIsPageLoading(false);
        console.log(error);
        throw error;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("bob check", advisorInfo);
  }, [advisorInfo]);

  const termsModal = ["_all", "1", "2", "3", "4", "5"];

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleExtraAddressChange = (event) => {
    setExtraAddress(event.target.value);
  };

  const handleDownloadClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.app.appwebview&pli=1&referrer=campaign_id%3dkr_thankyou";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        "https://apps.apple.com/app/apple-store/id948490555?pt=119667191&ct=kr_thankyou&mt=8";
    } else {
      alert("Please use mobile device to download our app!");
    }
  };

  const handleVerify = () => {
    if (name && phoneNumber.length === 11) {
      if (seconds > 0) {
        setModal("verification");
      } else {
        const header = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${base64data}`,
          },
        };
        const payload = {
          verificationType: "DL",
          phoneNumber: phoneNumber,
          verificationCodeExpirySeconds: 60,
        };
        apiCall({
          method: "POST",
          header: header,
          payload: payload,
          endPoint: process.env.REACT_APP_REQUIRE_SMS,
        }).then((data) => {
          setModal("verification");
          setSeconds(60);
          setExpiredTimer(60);
          setCorrectSMS(data.data.verificationCode);
        });
      }
    }
  };

  const handleResent = () => {
    if (seconds <= 0) {
      handleVerify();
    }
  };

  const handleSMSVerification = () => {
    const code = currentSMS.join("");
    const header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${base64data}`,
      },
    };
    const payload = {
      verificationType: "DL",
      phoneNumber: phoneNumber,
      verificationCode: code,
    };
    apiCall({
      method: "POST",
      header: header,
      payload: payload,
      endPoint: process.env.REACT_APP_VERIFY_SMS,
    }).then((data) => {
      if (data.data.status === "success") {
        setIsVerified(true);
        closeModal();
      } else if (data.data.status === "failure") {
        setPass(false);
      }
    });
  };

  const handleCheckEvent = (number) => {
    if (checkedItems.includes(number)) {
      const replica = checkedItems;
      setCheckedItems(replica.filter((v) => v !== number));
    } else {
      openModal(number);
    }
  };

  const formatPhoneNumber = (number, encrypt = false) => {
    if (number?.length !== 11) {
      return number;
    }
    const part1 = number.slice(0, 3);
    const part2 = encrypt ? "xxxx" : number.slice(3, 7);
    const part3 = number.slice(7);

    return `${part1}-${part2}-${part3}`;
  };

  const openModal = (title) => {
    setModal(title);
  };

  const closeModal = () => {
    setModal("");
  };
  const modalRef = useRef(null);

  useEffect(() => {
    console.log(window.daum);
    if ((!window.daum || !window.daum.Postcode) && document) {
      const script = document.createElement("script");
      script.src =
        "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        new window.daum.Postcode({
          oncomplete: function (data) {
            setAddress(data.address);
            setAddressInfo({
              Address1: data.address,
              ZIP: data.zonecode,
              SGG_CD: data.sigunguCode,
              ROAD_CD: data.roadnameCode,
              EMD_CD: data.bcode,
              BULD_NO: data.buildingCode,
            });
            setShowAddressModal(false);
          },
          width: "100%",
          height: "100%",
        }).embed(modalRef.current);
      };
      document.body.appendChild(script);
    } else {
      new window.daum.Postcode({
        oncomplete: function (data) {
          setAddress(data.address);
          setAddressInfo({
            Address1: data.address,
            ZIP: data.zonecode,
            SGG_CD: data.sigunguCode,
            ROAD_CD: data.roadnameCode,
            EMD_CD: data.bcode,
            BULD_NO: data.buildingCode,
          });
          setShowAddressModal(false);
        },
        width: "100%",
        height: "100%",
      }).embed(modalRef.current);
    }
  }, [showAddressModal]);

  useEffect(() => {
    if (allChecked && checkedItems.length !== 5) {
      setCheckedItems(["1", "2", "3", "4", "5"]);
    } else if (!allChecked) {
      setCheckedItems([]);
    }
  }, [allChecked]);
  useEffect(() => {
    if (checkedItems.length === 5) {
      setAllChecked(true);
    }
  }, [checkedItems]);
  useEffect(() => {
    setPass(true);
  }, [currentSMS]);

  useEffect(() => {
    if (!kidsInfoCheckList.includes("1")) {
      setBirthday("");
      setKidName("");
      setSelectedGender("");
    }
    if (!kidsInfoCheckList.includes("0")) {
      setBornDay("");
    }
  }, [kidsInfoCheckList]);

  const handleClick = (event) => {
    const value = event.target.innerText;
    setRelation(value);
    setModal("");
  };

  const handleKidsInfoCheckboxChange = (e) => {
    const replica = [...kidsInfoCheckList];
    const index = replica.indexOf(e.target.value);
    if (index !== -1) {
      replica.splice(index, 1);
    } else {
      replica.push(e.target.value);
    }
    setKidsInfoCheckList(replica);
  };

  const handleSubmit = () => {
    setMissingItems("");
    if (
      name &&
      phoneNumber &&
      address &&
      relation &&
      kidsInfoCheckList &&
      ((kidsInfoCheckList.includes("0") && bornDay) ||
        (kidsInfoCheckList.includes("1") &&
          birthday &&
          kidName &&
          selectedGender)) &&
      checkedItems.includes("1") &&
      checkedItems.includes("2") &&
      checkedItems.includes("3")
    ) {
      if (!isVerified && !isFromEa) {
        setModal("unverified");
      } else {
        const header = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${base64data}`,
          },
        };
        const payload = {
          name: name,
          mobileNumber: formatPhoneNumber(phoneNumber),
          zipCode: addressInfo.ZIP,
          // kAddress: addressInfo.Address1 + extraAddress,
          address1: addressInfo.Address1,
          address2: extraAddress,
          sggCode: addressInfo.SGG_CD,
          roadCode: addressInfo.ROAD_CD,
          emdCode: addressInfo.EMD_CD,
          buildingNumber: addressInfo.BULD_NO,
          isPregnant: kidsInfoCheckList.includes("0"),
          parentRelation:
            relation === "엄마" ? "모" : relation === "아빠" ? "부" : "기타",
          childName: kidName,
          childBirthDate: birthday,
          dueDate: bornDay,
          memo: memo,
          cldlType: "DL",
          leadType: advisorInfo.lType,
          mediaCode: advisorInfo.mediaCode,
          companyNumber: advisorInfo.advisorCode,
        };
        if (advisorInfo.lType === "GD") {
          payload.localMarketingId = advisorInfo.localMarketingId;
        } else if (advisorInfo.lType === "BD" || advisorInfo.lType === "LD") {
          payload.categoryId = advisorInfo.categoryId;
        }
        if (kidsInfoCheckList.includes("1")) {
          payload.childGender = selectedGender;
        }
        console.log("payload", payload);
        apiCall({
          method: "POST",
          header: header,
          payload: payload,
          endPoint: process.env.REACT_APP_ADD_DIRECT_LEAD,
        })
          .then((response) => {
            console.log("then response", response);
            if (
              response.data.status === "SUCCESS" ||
              response.data.status === "DUPLICATE_CREATION"
            ) {
              setModal("success");
            } else if (response.data.status === "STOP_CREATION") {
              setModal("duplicated");
            }
          })
          .catch((e) => {
            console.log("add lead error", e);
            setModal("failed");
          });
      }
    }
    if (!name) {
      setMissingItems("name");
      return;
    }
    if (!phoneNumber) {
      setMissingItems("mobile");
      return;
    }
    if (!address) {
      setMissingItems("address");
      return;
    }
    if (!relation) {
      setMissingItems("relation");
      return;
    }
    if (kidsInfoCheckList.length === 0) {
      setMissingItems("kidsInfo");
      return;
    }
    if (kidsInfoCheckList.includes("1") && !kidName) {
      setMissingItems("kidName");
      return;
    }
    if (kidsInfoCheckList.includes("1") && !birthday) {
      setMissingItems("birthday");
      return;
    }
    if (kidsInfoCheckList.includes("1") && !selectedGender) {
      setMissingItems("gender");
      return;
    }
    if (kidsInfoCheckList.includes("0") && !bornDay) {
      setMissingItems("bornDay");
      return;
    }
    if (
      !(
        checkedItems.includes("1") &&
        checkedItems.includes("2") &&
        checkedItems.includes("3")
      )
    ) {
      setMissingItems("terms");
      return;
    }
  };
  if (isPageLoading) {
    return (
      <div className="App" id="root">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="container">
          <img src={dweLogo} className="logo" alt="logo" />
          <p className="title">고객 정보</p>
        </div>
        <div className="loader-container">
          <div>
            <span className="loader" />
          </div>
        </div>
      </div>
    );
  }
  if (!guid || !advisorInfo) {
    return (
      <div className="App" id="root">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="container">
          <img src={dweLogo} className="logo" alt="logo" />
          <p className="title">고객 정보</p>
          <p className="expired-text">
            QR코드가 만료되었습니다. 이 페이지를 닫고 다시 생성한 후 스캔하세요.
            감사합니다.
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="App" id="root">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <div className="container">
        <img src={dweLogo} className="logo" alt="logo" />
        <p className="title">고객 정보</p>
        {!showNameCard && (
          <div className="form-container">
            <p className="form-title">정보입력</p>
            <div className="form-content">
              <div className="first-item">
                <p className="item-title">성함</p>
                <div>
                  <input
                    className={`item-input ${
                      missingItems === "name" ? "missing-field" : ""
                    }`}
                    placeholder="성함을 입력해 주세요"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                {missingItems === "name" && (
                  <p className="required-message">필수 항목을 입력해 주세요.</p>
                )}
              </div>
              <div className="item">
                <p className="item-title">휴대폰번호</p>
                <div className="two-part">
                  <input
                    className={`item-input ${isFromEa ? "" : "w-65"} ${
                      missingItems === "mobile" ? "missing-field" : ""
                    } ${isVerified ? "gray" : ""}`}
                    placeholder="’-’없이 입력"
                    type="number"
                    value={phoneNumber}
                    disabled={isVerified}
                    onChange={handlePhoneNumberChange}
                  />
                  <div
                    className={`verify-button w-31 ${
                      phoneNumber.length === 11 && name && !isVerified
                        ? "black"
                        : ""
                    } ${isFromEa ? "d-none" : ""}`}
                  >
                    <p
                      className={`button-text center ${
                        phoneNumber.length === 11 && name ? "" : "is-empty"
                      } ${isVerified ? "gray" : ""}`}
                      onClick={() => {
                        if (!isVerified) {
                          handleVerify();
                        }
                      }}
                    >
                      본인인증
                    </p>
                  </div>
                </div>
                {missingItems === "mobile" && (
                  <p className="required-message">필수 항목을 입력해 주세요.</p>
                )}
                {phoneNumber.length > 0 && phoneNumber.length !== 11 && (
                  <p className="required-message">
                    올바른 휴대폰 번호를 입력해주세요.
                  </p>
                )}
              </div>
              {isVerified && (
                <p className="verified-text">본인인증이 완료되었습니다.</p>
              )}
              <div className="item">
                <p className="item-title">주소</p>
                <div className="two-part">
                  <input
                    className={`item-input w-75 ${
                      missingItems === "address" ? "missing-field" : ""
                    }`}
                    placeholder="도로명, 지번, 건물명 검색"
                    value={address}
                    onChange={handleAddressChange}
                  />
                  <div
                    className="verify-button w-21 black"
                    onClick={() => {
                      setShowAddressModal(true);
                    }}
                  >
                    <p className="button-text center">검색</p>
                  </div>
                </div>
                {missingItems === "address" && (
                  <p className="required-message">필수 항목을 입력해 주세요.</p>
                )}
                <div>
                  <input
                    className="item-input"
                    placeholder="상세주소"
                    value={extraAddress}
                    onChange={handleExtraAddressChange}
                  />
                </div>
              </div>
              <div className="item">
                <p className="item-title">자녀와의 관계</p>
                <div
                  className={`item-input placeholder select-box ${
                    relation ? "black-text" : ""
                  }`}
                  onClick={() => {
                    openModal("relation");
                  }}
                >
                  <p>{relation ? relation : "성함을 입력해 주세요"}</p>
                  <img src={dropdown} alt="dropdown" />
                </div>
              </div>
              {missingItems === "relation" && (
                <p className="required-message">필수 항목을 입력해 주세요.</p>
              )}
              <div className="item">
                <p className="item-title">자녀 정보</p>
                <label className="check-box-item">
                  <input
                    type="checkbox"
                    value={0}
                    checked={kidsInfoCheckList.includes("0")}
                    onChange={handleKidsInfoCheckboxChange}
                  />
                  <p className="check-box-text">임신 중이예요.</p>
                </label>
                <div
                  className={`kid-info ${
                    kidsInfoCheckList.includes("0") ? "" : "d-none"
                  }`}
                >
                  <p className="item-title">출산예정일</p>
                  <label
                    htmlFor="born-date-input"
                    className={`item-input w-65 right-input placeholder select-box ${
                      bornDay ? "black-text" : ""
                    }`}
                  >
                    <p>{bornDay ? bornDay : "출산예정일"}</p>
                    <img src={calendar} alt="calendar" />
                  </label>
                </div>
                <input
                  type="date"
                  id="born-date-input"
                  className="calendar-input"
                  onChange={(e) => setBornDay(e.target.value)}
                />
                {missingItems === "bornDay" && (
                  <div className="right-message">
                    <p className="required-message w-65">
                      필수 항목을 입력해 주세요.
                    </p>
                  </div>
                )}
                <label className="check-box-item">
                  <input
                    type="checkbox"
                    value={1}
                    checked={kidsInfoCheckList.includes("1")}
                    onChange={handleKidsInfoCheckboxChange}
                  />
                  <p className="check-box-text">자녀가 있어요.</p>
                </label>
                {missingItems === "kidsInfo" && (
                  <p className="required-message mt-20">
                    필수 항목을 입력해 주세요.
                  </p>
                )}
                <div
                  className={`kid-infos ${
                    kidsInfoCheckList.includes("1") ||
                    kidsInfoCheckList.includes("0")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <div
                    className={`kid-info ${
                      kidsInfoCheckList.includes("1") ? "" : "d-none"
                    }`}
                  >
                    <p className="item-title">자녀명</p>
                    <input
                      className={`item-input w-65 right-input ${
                        missingItems === "kidName" ? "missing-field" : " "
                      }`}
                      placeholder="자녀명 입력"
                      value={kidName}
                      onChange={(e) => {
                        setKidName(e.target.value);
                      }}
                    />
                  </div>
                  {missingItems === "kidName" && (
                    <div className="right-message">
                      <p className="required-message w-65">
                        필수 항목을 입력해 주세요.
                      </p>
                    </div>
                  )}
                  <div
                    className={`kid-info ${
                      kidsInfoCheckList.includes("1") ? "" : "d-none"
                    }`}
                  >
                    <p className="item-title">자녀생일</p>
                    <label
                      htmlFor="birthday-input"
                      className={`item-input w-65 right-input placeholder select-box ${
                        birthday ? "black-text" : ""
                      }`}
                    >
                      <p>{birthday ? birthday : "자녀생일"}</p>
                      <img src={calendar} alt="calendar" />
                    </label>
                  </div>
                  {missingItems === "birthday" && (
                    <div className="right-message">
                      <p className="required-message w-65">
                        필수 항목을 입력해 주세요.
                      </p>
                    </div>
                  )}
                  <div
                    className={`kid-info ${
                      kidsInfoCheckList.includes("1") ? "" : "d-none"
                    }`}
                  >
                    <p className="item-title">자녀성별</p>
                    <div className="right-input w-65">
                      <label className="radio-option">
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          checked={selectedGender === "male"}
                          onChange={(e) => setSelectedGender(e.target.value)}
                        />
                        남
                      </label>
                      <label className="radio-option">
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={selectedGender === "female"}
                          onChange={(e) => setSelectedGender(e.target.value)}
                        />
                        여
                      </label>
                    </div>
                  </div>
                  {missingItems === "gender" && (
                    <div className="right-message">
                      <p className="required-message w-65">
                        필수 항목을 입력해 주세요.
                      </p>
                    </div>
                  )}
                  <input
                    type="date"
                    id="birthday-input"
                    className="calendar-input"
                    onChange={(e) => setBirthday(e.target.value)}
                  />
                </div>
                <div className="item">
                  <p className="item-title">메모 (선택사항)</p>
                  <div>
                    <textarea
                      className="item-input higher"
                      placeholder="문의 및 요청사항이 있으시면 입력해 주세요"
                      value={memo}
                      onChange={(e) => {
                        setMemo(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!showNameCard && (
          <div className="form-container">
            <div
              className="form-title"
              onClick={() => {
                if (!allChecked) {
                  openModal("_all");
                } else {
                  setAllChecked(false);
                }
              }}
            >
              <label className="check-box-title">
                <input type="checkbox" checked={allChecked} />
                <p className="check-box-text">전체 약관동의</p>
              </label>
            </div>
            <div className="form-content">
              <label className="check-box-item">
                <input
                  type="checkbox"
                  checked={checkedItems.includes("1")}
                  onClick={() => {
                    handleCheckEvent("1");
                  }}
                />
                <p className="check-box-text">(필수) 개인정보 수집이용 동의</p>
              </label>
              <label className="check-box-item">
                <input
                  type="checkbox"
                  checked={checkedItems.includes("2")}
                  onClick={() => {
                    handleCheckEvent("2");
                  }}
                />
                <p className="check-box-text">
                  (필수) 개인정보 제 3자 제공 동의
                </p>
              </label>
              <label className="check-box-item">
                <input
                  type="checkbox"
                  checked={checkedItems.includes("3")}
                  onClick={() => {
                    handleCheckEvent("3");
                  }}
                />
                <p className="check-box-text">
                  (필수) 개인정보 국외이전에 동의
                </p>
              </label>
              <label className="check-box-item">
                <input
                  type="checkbox"
                  checked={checkedItems.includes("4")}
                  onClick={() => {
                    handleCheckEvent("4");
                  }}
                />
                <p className="check-box-text">
                  (선택) 광고성 정보 전송수신 활용에 동의
                </p>
              </label>
              <label className="check-box-item">
                <input
                  type="checkbox"
                  checked={checkedItems.includes("5")}
                  onClick={() => {
                    handleCheckEvent("5");
                  }}
                />
                <p className="check-box-text">
                  (선택) 마케팅 및 광고를 위한 활용에 동의
                </p>
              </label>
              {missingItems === "terms" && (
                <p className="required-message mt-20">
                  이용약관과 개인정보 수집 및 이용에 관한 안내를 모두 동의해
                  주세요.
                </p>
              )}
            </div>
          </div>
        )}
        {!showNameCard && (
          <p className="confirm-button mt-40 mb-20" onClick={handleSubmit}>
            확인
          </p>
        )}
        {showNameCard && (
          <div className="name-card-container">
            <div className="name-card-content">
              <img src={dweLogo} className="logo" alt="logo" />
              <p className="thank">감사합니다.</p>
              <p className="english-name">{advisorInfo?.englishName}</p>
              <div className="name-title">
                <p className="name">{advisorInfo?.realName}</p>
                <p className="bar">&nbsp;l&nbsp;</p>
                <p className="job-title">
                  {"World Family " +
                    (advisorInfo?.companyTitle === "MGR"
                      ? "매니저"
                      : "어드바이저")}
                </p>
              </div>
              <p className="mobile">{formatPhoneNumber(advisorInfo?.mobile)}</p>
            </div>
          </div>
        )}
        {showNameCard && (
          <p
            className="confirm-button mt-40 mb-20"
            onClick={handleDownloadClick}
          >
            체험판 앱 설치
          </p>
        )}
        <div className={`address-modal ${showAddressModal ? "" : "d-none"}`}>
          <div className="modal-title">
            <div className="close-icon">
              <img
                src={close}
                alt="close"
                onClick={() => {
                  setShowAddressModal(false);
                }}
              />
            </div>
            <p className="modal-title-text">주소검색</p>
          </div>
          <div
            ref={modalRef}
            className="mt-40"
            style={{
              width: "100%",
              height: "470px",
            }}
          />
        </div>

        <Modal
          isOpen={modal === "relation"}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "0",
              left: "0",
              right: "0",
              top: "auto",
              height: "40%",
              padding: "20px",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div className="modal-title">
            <div className="close-icon" onClick={closeModal}>
              <img src={close} alt="close" />
            </div>
            <p className="modal-title-text">자녀와의 관계</p>
          </div>
          <div className="options">
            <p className="relation-option" onClick={handleClick}>
              엄마
            </p>
            <p className="relation-option" onClick={handleClick}>
              아빠
            </p>
            <p className="relation-option" onClick={handleClick}>
              그 외
            </p>
          </div>
        </Modal>
        <Modal
          isOpen={termsModal.includes(modal)}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "0",
              left: "0",
              right: "0",
              top: "auto",
              height: "90%",
              padding: "20px",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              display: "flex",
              flexDirection: "column",
              gap: 40,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div className="modal-title">
            <div className="close-icon" onClick={closeModal}>
              <img src={close} alt="close" />
            </div>
            <p className="modal-title-text">약관동의</p>
          </div>
          <div className="pdf-wrapper">
            <iframe
              width={window.innerWidth - 20}
              height={"85%"}
              src={`https://dwe.worldfamilyenglish.co.kr/terms_trialmode_agreement${modal}/`}
            />
            <div className="center mt-20">
              <p
                className="confirm-button"
                onClick={() => {
                  if (modal === "_all") {
                    setAllChecked(true);
                  } else {
                    const replica = [...checkedItems];
                    const number = modal?.slice(-1);
                    replica.push(number);
                    setCheckedItems(replica);
                  }
                  setModal("");
                }}
              >
                동의하기
              </p>
            </div>
          </div>
        </Modal>
        {/* <Modal
          isOpen={modal === "calendar"}
          onRequestClose={() => {
            if (isPregnantMode) {
              setIsPregnantMode(false);
            }
            setSelectedDate(new Date());
            closeModal();
          }}
          contentLabel="Select Date"
          style={{
            content: {
              bottom: "0",
              left: "0",
              right: "0",
              top: "auto",
              height: modalHeight,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div className="modal-title">
            <div
              className="close-icon"
              onClick={() => {
                if (isPregnantMode) {
                  setIsPregnantMode(false);
                }
                setSelectedDate(new Date());
                closeModal();
              }}
            >
              <img src={close} alt="close" />
            </div>
            <p className="modal-title-text">자녀생일</p>
          </div>
          <div className="center">
            <InfiniteCalendar
              width={400}
              height={window.innerHeight * 0.3}
              selected={selectedDate}
              onSelect={(date) => {
                setSelectedDate(date);
              }}
            />
          </div>
          <div className="center mt-20">
            <p
              className="confirm-button"
              onClick={() => {
                if (isPregnantMode) {
                  setBornDay(dayjs(selectedDate).format("YYYY-MM-DD"));
                  setIsPregnantMode(false);
                } else {
                  setBirthday(dayjs(selectedDate).format("YYYY-MM-DD"));
                }
                setSelectedDate(new Date());
                setModal("");
              }}
            >
              확인
            </p>
          </div>
        </Modal> */}
        <Modal
          isOpen={modal === "verification"}
          onRequestClose={() => {
            closeModal();
            setCurrentSMS(["", "", "", ""]);
          }}
          contentLabel="Mobile verification"
          style={{
            content: {
              bottom: "0",
              left: "0",
              right: "0",
              top: "auto",
              height: `${window.innerHeight * 0.4}px`,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <p className="verify-title">
            {formatPhoneNumber(phoneNumber, true)} 로 전송된 인증 코드를
            입력하세요. {correctSMS ? `(${correctSMS})` : ""}
          </p>
          <div className="sms-container">
            {currentSMS.map((num, index) => (
              <input
                className={`${pass ? "" : "red-border"} sms-box `}
                key={index}
                ref={(el) => (SMSInputs.current[index] = el)}
                type="text"
                inputMode="numeric" // Ensure numeric keyboard on mobile
                maxLength={1}
                value={num}
                onChange={(e) => handleSMSChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
            <div className="countdown-box" onClick={handleResent}>
              {seconds > 0 ? (
                <p className="button-text">{seconds}초</p>
              ) : (
                <p className="required-message button-text">재전송</p>
              )}
            </div>
          </div>
          <p className={`required-message ${pass ? "hidden" : ""}`}>
            {expiredTimer > 0
              ? "잘못된 코드 입니다."
              : "잘못된 코드이거나 입력 시간이 만료되었습니다."}
          </p>
          <div className="button-group">
            <p
              className={`verify-modal-button ${
                isAndroid ? "android-btn" : ""
              }`}
              onClick={() => {
                closeModal();
                setCurrentSMS(["", "", "", ""]);
              }}
            >
              취소
            </p>
            <p
              className={`verify-modal-button ${
                currentSMS.some((element) => element === "") || seconds <= 0
                  ? "gray-bg"
                  : "red-bg"
              }  ${isAndroid ? "android-btn" : ""}`}
              onClick={() => {
                if (
                  !currentSMS.some((element) => element === "") &&
                  seconds > 0
                ) {
                  handleSMSVerification();
                }
              }}
            >
              확인
            </p>
          </div>
        </Modal>
        <Modal
          isOpen={modal === "unverified"}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "40%",
              left: "8%",
              right: "auto",
              top: "auto",
              height: "25%",
              width: "84%",
              padding: "36px 0",
              borderRadius: "12px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <p className="popup-title">휴대폰 본인인증이 필요합니다.</p>
          <div className="centered">
            <p className="popup-content">
              본인확인을 위한 인증절차를 진행해 주세요.
            </p>
          </div>
          <div className="centered">
            <div className="popup-button-group">
              <div
                className="popup-button back"
                onClick={() => {
                  setModal("");
                }}
              >
                <p>취소</p>
              </div>
              <div
                className="popup-button go"
                onClick={() => {
                  setModal("");
                  handleVerify();
                }}
              >
                <p>본인인증</p>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal === "failed"}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "40%",
              left: "8%",
              right: "auto",
              top: "auto",
              height: "25%",
              width: "84%",
              padding: "36px 0",
              borderRadius: "12px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <p className="popup-title">등록 실패</p>
          <div className="centered">
            <p className="popup-content">
              에러가 발생하여 정보 등록이 실패되었어요.
              <br />
              잠시 후 다시 시도해주세요.
            </p>
          </div>
          <div className="centered">
            <div
              className="popup-button go full-length-button"
              onClick={() => {
                setModal("");
              }}
            >
              <p>확인</p>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal === "duplicated"}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "40%",
              left: "8%",
              right: "auto",
              top: "auto",
              height: "25%",
              width: "84%",
              padding: "36px 0",
              borderRadius: "12px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <p className="popup-title">등록 실패</p>
          <div className="centered">
            <p className="popup-content">
              이미 등록된 고객입니다.
              <br />
              어드바이저에게 문의해 주세요.
            </p>
          </div>
          <div className="centered">
            <div
              className="popup-button go full-length-button"
              onClick={closeModal}
            >
              <p>확인</p>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal === "success"}
          onRequestClose={closeModal}
          style={{
            content: {
              bottom: "40%",
              left: "8%",
              right: "auto",
              top: "auto",
              height: "25%",
              width: "84%",
              padding: "36px 0",
              borderRadius: "12px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <p className="popup-title">등록이 완료 되었습니다.</p>
          <div className="centered">
            <p className="popup-content">
              감사합니다. 고객님의 정보 등록이 성공적으
              <br />로 완료 되었습니다.
            </p>
          </div>
          <div className="centered">
            <div
              className="popup-button go full-length-button"
              onClick={() => {
                closeModal();
                setShowNameCard(true);
              }}
            >
              <p>확인</p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default App;
